import { QueryClient, dehydrate } from "@tanstack/react-query";
import { getPathFromUrl } from "@gonoodle/gn-universe-utils";
import cookie from "cookie";

import api from "../api";
import BugsnagClient from "../utils/bugsnag";
import {
  USER_TYPES,
  QUERY_KEYS,
  ROUTE_PERMISSIONS,
  ROUTE_PREFIX,
} from "../constants";

export default function withAuthorization(
  getServerSideProps,
  permission,
  redirectRoute = "/",
) {
  // Anonymous represents authentication routes that can be accessed by transient users
  // Public represents routes that can be accessed by logged in users and transient users
  // Private represents routes that can be accessed by logged in users only
  const isAnonymousRoute = permission === ROUTE_PERMISSIONS.ANONYMOUS;
  const isPublicRoute = permission === ROUTE_PERMISSIONS.PUBLIC;
  const isPrivateRoute = permission === ROUTE_PERMISSIONS.PRIVATE;

  return async function wrapper(ctx) {
    const queryClient = new QueryClient();
    const client = api(ctx);
    let user = null;

    try {
      // Get session from cookies
      const cookies = cookie.parse(ctx?.req?.headers?.cookie || "");
      const accessToken = cookies?.access_token;

      // Try to get user from session
      if (accessToken) {
        user = await queryClient.fetchQuery([QUERY_KEYS.USER], client.getUser, {
          staleTime: Infinity,
        });
      }
    } catch (e) {
      BugsnagClient.notify(e);
    }

    const isLoggedIn = [
      USER_TYPES.CLASSROOM_TEACHER.id,
      USER_TYPES.FAMILY.id,
    ].includes(user?.userTypeId);

    switch (true) {
      // Create transient user
      case (isPublicRoute || isAnonymousRoute) && !user:
        user = await queryClient.fetchQuery(
          [QUERY_KEYS.USER],
          client.createUser,
        );
        break;
      default:
    }

    // Redirects
    switch (true) {
      // Redirect transient users away from private pages
      case isPrivateRoute && !isLoggedIn:
        return { redirect: { destination: redirectRoute, permanent: false } };
      // Redirect logged in users away from anonymous only routes
      case isAnonymousRoute && isLoggedIn:
        return { redirect: { destination: redirectRoute, permanent: false } };
      case isLoggedIn &&
        user.selectedClassroomId === null &&
        getPathFromUrl(ctx.resolvedUrl) !== `/${ROUTE_PREFIX.PROFILES}`:
        return {
          redirect: {
            destination: `/${ROUTE_PREFIX.PROFILES}`,
            permanent: false,
          },
        };
      default:
    }

    const pageProps = await getServerSideProps(ctx, user);

    // eslint-disable-next-line consistent-return
    return {
      ...pageProps,
      props: {
        ...pageProps.props,
        dehydratedState: pageProps.props?.dehydratedState
          ? {
              mutations: [
                ...dehydrate(queryClient).mutations,
                ...pageProps.props.dehydratedState.mutations,
              ],
              queries: [
                ...dehydrate(queryClient).queries,
                ...pageProps.props.dehydratedState.queries,
              ],
            }
          : dehydrate(queryClient),
      },
    };
  };
}
